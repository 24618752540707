import { OfferQuestionnaireValidatorFunctions, Question, Questionnaire, QuestionnaireWrapper, RequestForOfferQuestionnaireValidator, Tender, parseDecimalDk } from '@dims/components';
import { DateTime } from 'luxon';
import deliveryAgreementIds from '@/models/DeliveryAgreementIds';

export default class RequestQuestionnaireValidator implements RequestForOfferQuestionnaireValidator {
  questionnaire;
  private readonly offerQuestionnaireValidatorFunctions;

  constructor(readonly tender: Tender, questionnaire: Questionnaire) {
    this.offerQuestionnaireValidatorFunctions = new OfferQuestionnaireValidatorFunctions();
    this.questionnaire = new QuestionnaireWrapper(
      this.tender.agreementConfiguration.questionnaires.requestForOffer,
      questionnaire,
    );
  }
  get incomplete() {
    return !this.questionnaire.content.complete;
  }
  minValue(question: Question): number | undefined {
    if (question.dataFieldType === 'Number') {
      return 0;
    }
    return undefined;
  }

  maxValue(_question: Question): number | undefined {
    return undefined;
  }

  minDate(question: Question): string | undefined {
    if (question.questionId === deliveryAgreementIds.idDeliveryDate) {
      return this.tender.deadlineForTender ?? undefined;
    }
    if (question.dataFieldType === 'Date') {
      return DateTime.local().toISODate();
    }
    return undefined;
  }

  get isSubcriteriaValid() {
    if (this.tender.awardCriteriaType === 'TCO') { return true; }
    return this.sumOfSubCriteria === 100;
  }

  get sumOfSubCriteria() {
    const sum = (arr: number[]) => arr.reduce((previousq, q) => previousq + q, 0);
    const weightQuestionIds = [
      deliveryAgreementIds.idDriverSeatWeight,
      deliveryAgreementIds.idEnterExitWeight,
      deliveryAgreementIds.idInstrumentationWeight,
      deliveryAgreementIds.idDrivingWeight,
      deliveryAgreementIds.idComfortWeight,
      deliveryAgreementIds.idViewWeight,
      deliveryAgreementIds.idNoiseWeight,
      deliveryAgreementIds.idSecurityWeight,
    ];
    const values = this.questionnaire.content.questions
      .filter((q) => weightQuestionIds.includes(q.questionId))
      .map((q) => (q.answers?.[0] && q.answers[0].value !== '' ? parseFloat(q.answers[0].value) : 0));
    return sum(values);
  }

  get subCriteriaValidation() {
    if (this.isSubcriteriaValid) {
      return null;
    }
    const sum = this.sumOfSubCriteria;
    return { message: `Summen af delkriterierne skal være 100% (Summen er ${sum})` };
  }

  get isAllNumericQuestionsValid() {
    return this.questionnaire.content.questions.every((q) => {
      if (q.dataFieldType === 'Number') {
        const answer = this.offerQuestionnaireValidatorFunctions.getQuestionAnswer(q);
        if (!answer) {
          return false;
        }
        const value = parseDecimalDk(answer.toString());
        const minValue = this.minValue(q);
        const maxValue = this.maxValue(q);
        if (value === null
          || (minValue && minValue > value)
          || (maxValue && maxValue < value)) {
          return false;
        }
      }
      return true;
    });
  }
}
